<!-- web搜索 -->
<div class="content-box">
  <div class="search mutual-opacity" cdkOverlayOrigin #trigger #origin="cdkOverlayOrigin" (click)="openSearch()">
    <!-- 静态示意框（用户实际操作是下方searchMain里的搜索框） -->
    <app-customize-input
      width="100%"
      [fixedHeight]="true"
      [readonly]="true"
      [placeholder]="'sear_g' | translate"
      [leftIcon]="'icon-search'"
      [(value)]="searchValue"
    ></app-customize-input>
  </div>
  <!-- 跟随弹出层 -->
  <ng-template
    cdkConnectedOverlay
    (backdropClick)="toggle()"
    [cdkConnectedOverlayWidth]="triggerRect?.width"
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayOrigin]="origin"
  >
    <ng-container *ngTemplateOutlet="searchMain"></ng-container>
  </ng-template>
</div>

<!-- 搜索主体 -->
<ng-template #searchMain>
  <search-game-base [labelCodes]="labelCodes" [searchValue]="searchValue"></search-game-base>
</ng-template>
