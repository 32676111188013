import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { delay, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { GameApi } from 'src/app/shared/apis/game.api';
import { GameSearch, NewGameList } from 'src/app/shared/interfaces/game.interface';
import { ResponseData } from 'src/app/shared/interfaces/response.interface';
import { LocalStorageService } from 'src/app/shared/service/localstorage.service';
import { MiniGameService } from '../../minigame.service';

@UntilDestroy()
@Component({
  selector: 'search-game-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class SearchBaseComponent implements OnInit, OnChanges {
  constructor(
    public appService: AppService,
    private _gameApi: GameApi,
    private _router: Router,
    private _localStorageService: LocalStorageService,
    private _miniGameService: MiniGameService,
    private _activatedRoute: ActivatedRoute,
  ) {}
  /**为你推荐需要的codes */
  @Input() labelCodes: string[] = [];

  @Input() searchValue: string = '';

  @Input() maxHeight: string = '400px';

  @Output() beforeClickItem = new EventEmitter();

  @ViewChild('trigger') trigger!: ElementRef;

  public domain = window.location.origin;

  public labelCodesChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /** 大厅传过来code 搜索 */
  public gameList?: NewGameList;

  public loadingGameList: boolean = true;

  // 搜索相关
  public search$: Subject<string> = new Subject();
  public state$: Subject<boolean> = new Subject();
  public oldSearchValue!: string;
  public searchResult?: GameSearch;
  public loading!: boolean;
  public searchHistory: string[] = [];
  public searchDom: HTMLElement | null = null;

  /**搜索最低长度限制 */
  get limit() {
    return 1; //this.appService.languageCode === 'zh-cn' ? 1 : 1;
  }

  public ngOnInit() {
    // 搜索流，可以更科学的控制请求的发出，连续的请求会自动取消之前未完成的请求
    this.search$
      .pipe(
        distinctUntilChanged(),
        switchMap(v => this._gameApi.getGameByName(v).pipe(delay(200))),
      )
      .subscribe(res => this.afterSearch(res));

    this.updateSearchHistory();

    combineLatest([this._activatedRoute.paramMap, this.labelCodesChange$.pipe(switchMap(() => of(this.labelCodes)))])
      .pipe(untilDestroyed(this))
      .subscribe(([param, labelCodes]) => {
        const label = param.get('label');
        const sub = param.get('sub');
        const providerId = param.get('providerId');
        if (label) {
          this.getGameListByMultipleLabel([label]);
        } else if (providerId) {
          this.getGameListByMultipleLabel([], false, providerId == 'index' ? undefined : providerId);
        } else if (sub && sub !== 'index') {
          this.getGameListByMultipleLabel([sub], true);
        } else if (labelCodes.length > 0) {
          this.getGameListByMultipleLabel(labelCodes, true);
        } else {
          // h5且没有任何偏向，默认请求全部
          this.getGameListByMultipleLabel([]);
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.labelCodes && changes.labelCodes.currentValue.length > 0) {
      this.labelCodesChange$.next(true);
    }
  }

  // 准备搜索
  public onSearch() {
    if (this.searchValue.length < this.limit) return;
    if (this.searchValue === this.oldSearchValue) return; //和上一次相同直接显示之前的结果
    this.oldSearchValue = this.searchValue.trim();
    this.searchResult = undefined; //置空准备显示下一个结果
    this.loading = true;
    this.search$.next(this.searchValue.trim());
  }

  // 更新本地历史记录
  public updateSearchHistory(val?: string, delIndex?: number) {
    if (val)
      this._localStorageService.setValueByJsonStringify('gameSearchHistory', [val, ...this.searchHistory.slice(0, 4)]);
    if (delIndex !== undefined) {
      this.searchHistory.splice(delIndex, 1);
      this._localStorageService.setValueByJsonStringify('gameSearchHistory', this.searchHistory);
    }
    this.searchHistory = this._localStorageService.getValueByJsonParse('gameSearchHistory', []);
  }
  // 点击游戏
  public clickGameItem(item: any) {
    if (item?.webRedirectUrl) {
      this._router.navigateByUrl(`${this.appService.languageCode}/${item.webRedirectUrl}`);
    } else {
      this._router.navigateByUrl(`${this.appService.languageCode}/casino/games/${item.providerCatId}/${item.gameId}`);
    }
  }

  /**
   * 通过label 值获取 游戏
   *
   * @param label code
   * @param isHill 是否是 大厅
   * @param providerCatId
   */
  public getGameListByMultipleLabel(label: string[], isHill: boolean = false, providerCatId?: string) {
    this.loadingGameList = true;
    this._miniGameService
      .getGameListByMultipleLabel(label, false, 25, isHill, undefined, providerCatId)
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.loadingGameList = false;
        this.gameList = data;
      });
  }

  // 拉取搜索接口之后
  public afterSearch(res: ResponseData<GameSearch>) {
    this.loading = false;
    if (res?.success && res?.data) {
      if (!this.searchHistory.includes(this.searchValue.trim())) this.updateSearchHistory(this.searchValue.trim()); //记录里没有此关键词记录，保存到本地历史记录
      this.searchResult = res.data;
    }
  }
}
