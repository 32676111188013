import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomizeFormModule } from 'src/app/shared/components/customize-form/customize-form.module';
import { ScrollbarModule } from 'src/app/shared/components/scrollbar/scrollbar.module';
import { SwiperUnitModule } from 'src/app/shared/components/swiper-unit/swiper-unit.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SearchBaseComponent } from './base/base.component';
import { SearchGameDialogComponent } from './dialog/dialog.component';
import { SearchGameComponent } from './search-game/search-game.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    OverlayModule,
    CustomizeFormModule,
    SwiperUnitModule,
    ScrollbarModule,
  ],
  declarations: [SearchBaseComponent, SearchGameComponent, SearchGameDialogComponent],
  exports: [SearchBaseComponent, SearchGameComponent, SearchGameDialogComponent],
})
export class SearchGameModule {}
