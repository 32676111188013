<div class="content-box">
  <!-- 搜索框 -->
  <div class="search search-dark" appLifeObserve>
    <app-customize-input
      width="100%"
      [autoFocus]="true"
      [fixedHeight]="true"
      [placeholder]="'sear_g' | translate"
      [leftIcon]="'icon-search'"
      [onChangeDebounceTime]="0"
      (onChange)="onSearch()"
      [(value)]="searchValue"
      [trim]="false"
    >
    </app-customize-input>
  </div>

  <div class="result-box" [style.maxHeight]="maxHeight">
    <app-scrollbar>
      <div class="result-main">
        <!-- 不符合搜索条件 -->
        <div class="tip-text" *ngIf="searchValue.length < 1">{{ 'least_three' | translate }}</div>
        <!-- 无结果 -->
        <div
          class="tip-text"
          *ngIf="
            searchValue.length >= limit &&
            (searchResult?.gameInfo?.length ?? 0) < 1 &&
            (searchResult?.labelInfo?.length ?? 0) < 1 &&
            !loading
          "
        >
          {{ 'no_found' | translate }}
        </div>
        <!-- 搜索历史 -->
        <div class="search-history" *ngIf="searchValue.length < limit && searchHistory.length > 0">
          <div class="title">{{ 'rec_sear' | translate }}</div>
          <ng-container *ngFor="let item of searchHistory; let i = index">
            <p
              class="onact96"
              *ngIf="searchValue.length === 0 || item.toUpperCase().includes(searchValue.toUpperCase())"
              (click)="searchValue = item; onSearch()"
            >
              <span class="wrap"
                ><i class="icon-history"></i><span>{{ item }}</span></span
              >
              <i
                class="del-btn icon-close-simple mutual-opacity"
                (click)="$event.stopPropagation(); updateSearchHistory(undefined, i)"
              ></i>
            </p>
          </ng-container>
        </div>
        <!-- 搜索结果 -->
        <app-swiper-unit
          class="result-games"
          *ngIf="
            searchValue.length >= limit &&
            (loading || (searchResult?.gameInfo?.length ?? 0 > 0) || (searchResult?.labelInfo?.length ?? 0 > 0))
          "
          [loading]="loading"
          [hideCarouselOnNoData]="true"
          (beforeClickItem)="beforeClickItem.emit()"
          (clickItem)="clickGameItem($event)"
          [data]="searchResult?.gameInfo || []"
          [title]="'sear_res' | translate"
          [titleIcon]="'assets/images/game/search-i.svg'"
        >
          <div name="under-header" class="under-header">
            <div class="content-label" #contentLabel *ngIf="searchResult?.labelInfo?.length ?? 0 > 0">
              <ng-container *ngFor="let item of searchResult?.labelInfo">
                <div class="label-item" (click)="beforeClickItem.emit()">
                  <span
                    [routerLink]="['/', appService.languageCode, 'casino', 'category', item.labelId]"
                    class="label-text"
                  >
                    <a
                      [href]="domain + '/' + appService.languageCode + '/casino/category' + item.labelId"
                      (click)="$event.preventDefault()"
                    >
                      {{ item.labelName }}
                    </a>
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </app-swiper-unit>
      </div>

      <!-- 附加推荐栏 -->
      <app-swiper-unit
        class="attach-recommend"
        (beforeClickItem)="beforeClickItem.emit()"
        (clickItem)="clickGameItem($event)"
        [data]="gameList?.gameLists"
        [title]="gameList?.labelName"
        [titleIcon]="gameList?.icon"
      ></app-swiper-unit>
    </app-scrollbar>
  </div>
</div>
